import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

import { createEvent } from '~/utils/eventable';
import { isNotTabKey } from '../../utils/events';
import { supportsAspectRatio } from '../../utils/dom';
import { toInt } from '../../utils/numbers';
import { containsDigits } from '../../utils/strings';
import { useRestaurant } from '../../utils/withRestaurant';
import { BASE_Z_INDEX, makeStyles, classNames } from '../../utils/withStyles';
import CustomImg from '../../shared/CustomImg';

// This gallery produces Masonry-like layout by mapping property CSS grid-row to aspect ratio.
// Maximum number of rows in CSS-grid is 1000 (https://bugs.chromium.org/p/chromium/issues/detail?id=688640)
const MAX_GRID_ROWS = 1000;
// The less photos the gallery contain the more CSS grid rows can be used per photo.
// A photo with 1:1 aspect ratio will take 50 CSS-grid rows.
const MAX_GRID_ROWS_PER_UNIT = 50;

// When there are too many rows it's getting dangerous to use a lot of CSS-grid rows per photo (we can hit 1000 rows limit).
const MIN_GRID_ROWS_PER_UNIT = 12;

// Assuming the worst when all photos have a low aspect ratio 1:3
// the maximum number of photos stacked vertiacally will be 1000 * 1:3 / 12 ~= 27
const MIN_ASPECT_RATIO = 1 / 3;
const MAX_PHOTOS_PER_COLUMN = Math.floor(MAX_GRID_ROWS * MIN_ASPECT_RATIO / MIN_GRID_ROWS_PER_UNIT);

const MOBILE_ASPECT_RATIO = 1;

function computeGridRowsPerUnit({ columnsNumber, photosNumber }) {
  const photosPerColumn = Math.ceil(photosNumber / columnsNumber);
  const gridRowsPerUnit = MAX_GRID_ROWS * MIN_ASPECT_RATIO / photosPerColumn;
  return Math.floor(Math.max(MIN_GRID_ROWS_PER_UNIT, Math.min(MAX_GRID_ROWS_PER_UNIT, gridRowsPerUnit)));
}

function computeAspectRatio({ imageUploadedPhoto, screenWidth, columnsNumber }) {
  const width = imageUploadedPhoto?.width;
  const height = imageUploadedPhoto?.height;

  // imageUploadedPhoto has full image size. It gets scaled on Cloudflare.
  // The aspect ratio changes during the scale since dimensions are always of integer values.
  // We imitate scaling to get a similar bias as on Cloudflare.
  const adjustedWidth = Math.floor(screenWidth / columnsNumber);
  const adjustedHeight = Math.floor(adjustedWidth / width * height);
  return adjustedWidth / adjustedHeight;
}

function getAspectRatio({
  columnsNumber,
  // A hardcoded override. Has the highest priority.
  forcedAspectRatio,
  // Data from |uploaded_photos| database table. Contains width and height of the full-sized image.
  imageUploadedPhoto,
  src,
  screenWidth,
  // An aspect ratio computed from the actual image element.
  trueAspectRatio,
}) {
  if (forcedAspectRatio) return forcedAspectRatio;

  const computedAspectRatio = computeAspectRatio({
    columnsNumber,
    imageUploadedPhoto,
    screenWidth,
  }) || 1;

  if (trueAspectRatio && Math.abs(trueAspectRatio - computedAspectRatio) / trueAspectRatio > 0.02) {
    // The case when the true aspect ratio is significantly different from the database value is hypothetical.
    // Usually, it results in only tiny changes doing nothing good but harming Speed-Index.
    console.warn(`Image ${src} has wrong aspect ratio stored in the database: ${computedAspectRatio}. True aspect ratio: ${trueAspectRatio}`);
    return trueAspectRatio;
  }
  return computedAspectRatio;
}

function getGridRowSpan(screenWidth) {
  return (props => `span ${Math.ceil(props.gridRowsPerUnit / Math.max(MIN_ASPECT_RATIO, getAspectRatio({ ...props, screenWidth })))}`);
}

const useGridItemStyles = makeStyles(theme => ({
  gridItem: {
    aspectRatio: ({ preferHeightOverAspectRatio, forcedAspectRatio }) => (preferHeightOverAspectRatio ? 'auto' : forcedAspectRatio && MOBILE_ASPECT_RATIO),
    msGridRow: ({ index }) => index + 1,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      aspectRatio: ({ preferHeightOverAspectRatio, forcedAspectRatio }) => (preferHeightOverAspectRatio ? 'auto' : forcedAspectRatio),
      gridRow: getGridRowSpan(theme.breakpoints.values.md),
      msGridColumn: ({ columnsNumber, index }) => 1 + index % columnsNumber,
      msGridRow: ({ columnsNumber, index }) => Math.ceil((index + 1) / columnsNumber),
    },
    [theme.breakpoints.up('md')]: {
      gridRow: getGridRowSpan(theme.breakpoints.values.lg),
    },
    [theme.breakpoints.up('lg')]: {
      gridRow: getGridRowSpan(theme.breakpoints.values.xl),
    },
  },
}));

const GalleryPhoto = ({ alt, columnsNumber, forcedAspectRatio, gridRowsPerUnit, imageUploadedPhoto, index, onClick, onKeyDown, padding, src }) => {
  // A fallback for rare cases when aspect ratio stored in the database is wrong.
  const [trueAspectRatio, setTrueAspectRatio] = useState(undefined);

  // 'aspect-ratio' is a fresh CSS property unsupported on most legacy browser.
  // Another problem: it doesn't combine with some other CSS properties.
  // That's why GalleryPhoto has a fallback: setting 'height' CSS from JS.
  const [forcedHeight, setForcedHeight] = useState(undefined);
  const [preferHeightOverAspectRatio, setPreferHeightOverAspectRatio] = useState(false);
  const updateForcedHeight = useCallback(() => {
    if (forcedAspectRatio && preferHeightOverAspectRatio) {
      // Keep synced with the CSS 'aspect-ratio' property in |useGridItemStyles|
      const mobileHeight = window.innerWidth / MOBILE_ASPECT_RATIO;
      const desktopHeight = window.innerWidth / columnsNumber / forcedAspectRatio;
      setForcedHeight(window.innerWidth >= 768 ? desktopHeight : mobileHeight);
    }
  }, [forcedAspectRatio, preferHeightOverAspectRatio, columnsNumber]);

  useEffect(() => {
    // Checking |supportsAspectRatio| from |useEffect| to prevent SSR conflict.
    if (!supportsAspectRatio) setPreferHeightOverAspectRatio(true);
    updateForcedHeight();
    window.addEventListener('resize', updateForcedHeight);
    return () => {
      window.removeEventListener('resize', updateForcedHeight);
    };
  }, [updateForcedHeight]);

  const styles = useGridItemStyles({
    columnsNumber,
    forcedAspectRatio,
    gridRowsPerUnit,
    imageUploadedPhoto,
    index,
    preferHeightOverAspectRatio,
    src,
    trueAspectRatio,
  });

  const onImgLoad = React.useCallback((_, image) => {
    // |props| may contain inadequate image size.
    setTrueAspectRatio(image.width / image.height);

    // aspect-ratio doesn't play well with 'max-height' (see yonutz.popmenu.localhost:3000/)
    const imageHasMaxHeight = containsDigits(window.getComputedStyle(image).maxHeight);
    setPreferHeightOverAspectRatio(imageHasMaxHeight);
  }, []);

  return (
    <div
      aria-hidden
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={classNames(styles.gridItem, 'xblock' /* Legacy. Search for |xmasonry| comment below. Some clients wrote css selectors reffering this class. Please prefer CSS-grid to |xmasonry| (it's faster). */)}
    >
      <CustomImg
        alt={alt}
        desktopDownscale={columnsNumber}
        size="sm"
        onLoad={onImgLoad}
        src={src}
        style={{
          display: 'block',
          padding,
          width: '100%',
          ...(forcedAspectRatio ? {
            height: forcedHeight || '100%',
            objectFit: 'cover',
          } : {}),
        }}
      />
    </div>
  );
};

const useGridStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridAutoFlow: 'dense',
    gridTemplateColumns: '[first-line] 1fr [last-line]',
    msGridColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: ({ columnsNumber }) => `[first-line] repeat(${columnsNumber}, 1fr) [last-line]`,
      marginLeft: 'auto',
      marginRight: 'auto',
      msGridColumns: ({ columnsNumber }) => `(1fr)[${columnsNumber}]`,
      width: ({ photosNumber, maxColumns }) => (photosNumber >= maxColumns ? undefined : `${photosNumber * 100 / maxColumns}%`),
    },
  },
}));

function sliceIntoChunks(arr, chunkSize) {
  if (chunkSize <= 0) {
    throw new RangeError();
  }

  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

const setLightBoxAltTexts = (photos) => {
  let intervalId = null;
  let attempts = 0;
  const setImageAlt = () => {
    const imgElements = document.querySelectorAll('img.ril__image');
    if (imgElements.length > 0) {
      imgElements.forEach((imgEl) => {
        if (imgEl.alt !== 'Image') return;

        const currentPhoto = photos.find(photo => imgEl.src === photo.imageUrl);
        imgEl.setAttribute('alt', currentPhoto?.description);
      });
      clearInterval(intervalId);
    } else if (attempts >= 3) {
      clearInterval(intervalId);
    }
    attempts += 1;
  };
  intervalId = setInterval(setImageAlt, 100);
};

const CustomGallery = ({ eventableId, eventableType, photos, forcedAspectRatio, ...props }) => {
  const restaurant = useRestaurant();
  const [showGallery, setShowGallery] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const columnsNumber = Math.min(props.maxColumns, photos.length);
  const styles = useGridStyles({
    columnsNumber,
    maxColumns: props.maxColumns,
    photosNumber: photos.length,
  });

  const photo = photos[photoIndex];
  let prevIndex;
  let nextIndex;
  if (photos.length > 1) {
    prevIndex = photoIndex <= 0 ? photos.length - 1 : photoIndex - 1;
    nextIndex = photoIndex >= photos.length - 1 ? 0 : photoIndex + 1;
  }

  const handleImageClick = (e, index) => {
    if (isNotTabKey(e)) {
      setShowGallery(true);
      setPhotoIndex(index);
      if (eventableType) {
        createEvent({
          eventableId,
          eventableType,
          eventType: 'gallery_open_event',
          restaurantId: restaurant.id,
        });
      }
    }
  };

  const gridRowsPerUnit = computeGridRowsPerUnit({ columnsNumber, photosNumber: photos.length });

  const photoComponents = photos.map((image, i) => (
    <GalleryPhoto
      key={image.id}
      index={i}
      onClick={e => handleImageClick(e, i)}
      onKeyDown={e => handleImageClick(e, i)}
      alt={image.description ? image.description : `${restaurant.name || ''} Gallery`}
      placeholderQuality="high"
      size="sm"
      src={image.imageUrl}
      forcedAspectRatio={forcedAspectRatio}
      imageUploadedPhoto={image.imageUploadedPhoto}
      padding={`${toInt(props.galleryPadding || '0')}px`}
      columnsNumber={columnsNumber}
      gridRowsPerUnit={gridRowsPerUnit}
    />
  ));

  return (
    <React.Fragment>
      {sliceIntoChunks(photoComponents, MAX_PHOTOS_PER_COLUMN * columnsNumber).map((chunk, i) => (
        <div
          key={i}
          className={classNames(styles.grid, 'xmasonry' /* |xmasonry| class is for legacy. Some clients wrote css selectors reffering this class. Please prefer CSS-grid to |xmasonry| (it's faster). */)}
        >
          {chunk}
        </div>
      ))}

      {showGallery && photo && (
        <Lightbox
          closeLabel="Close Gallery"
          imageCaption={photo.description}
          mainSrc={photo.imageUrl}
          mainSrcThumbnail={photo.thumbnailUrl}
          nextSrc={typeof nextIndex === 'number' ? photos[nextIndex].imageUrl : null}
          nextSrcThumbnail={typeof nextIndex === 'number' ? photos[nextIndex].thumbnailUrl : null}
          onCloseRequest={() => setShowGallery(false)}
          prevSrc={typeof prevIndex === 'number' ? photos[prevIndex].imageUrl : null}
          prevSrcThumbnail={typeof prevIndex === 'number' ? photos[prevIndex].thumbnailUrl : null}
          reactModalStyle={{
            overlay: {
              zIndex: BASE_Z_INDEX + 1600,
            },
          }}
          onMoveNextRequest={typeof nextIndex === 'number' ? () => {
            setPhotoIndex(nextIndex);
            setLightBoxAltTexts(photos);
          } : null}
          onMovePrevRequest={typeof prevIndex === 'number' ? () => {
            setPhotoIndex(prevIndex);
            setLightBoxAltTexts(photos);
          } : null}
          onImageLoad={() => setLightBoxAltTexts(photos)}
        />
      )}
    </React.Fragment>
  );
};

CustomGallery.defaultProps = {
  eventableId: null,
  eventableType: null,
  forcedAspectRatio: undefined,
  galleryPadding: null,
};

CustomGallery.propTypes = {
  eventableId: PropTypes.number,
  eventableType: PropTypes.string,
  forcedAspectRatio: PropTypes.number,
  galleryPadding: PropTypes.string,
  maxColumns: PropTypes.number.isRequired,
  photos: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    imageUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  })).isRequired,
};

export default CustomGallery;
