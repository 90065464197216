import React from 'react';

import CustomGallery from '../photos/CustomGallery';
import SliderGallery from '../photos/SliderGallery';

const DEFAULT_MAX_COLUMNS = 3;
const DEFAULT_ASPECT_RATIO = 1;

const GallerySection = (props) => {
  if (props.galleryImages.length === 0) return null;

  if (props.galleryType === 'slider_gallery') {
    return <SliderGallery {...props} />;
  } else if (props.galleryType === 'masonry_gallery') {
    return (
      <CustomGallery
        maxColumns={props.galleryColumn || DEFAULT_MAX_COLUMNS}
        photos={props.galleryImages}
        galleryPadding={props.galleryPadding}
        eventableType="CustomPage"
        eventableId={props.customPageId}
      />
    );
  } else if (props.galleryType === 'grid_gallery') {
    // The next two const declarations are a fix for a bug introduced in https://github.com/Popmenu/popmenu/pull/4289
    // During the migration from XMasonry, we accidentally changed the default behavior for galleries with
    // one or two photos and with undefined |galleryColumn| (rare case).
    // XMasonry didn't treat such galleries as a special case, unlike the new CSS-grid impplementation.
    // CSS-grid implementation stretched such galleries to the full width (unlike XMasonry).
    // We cannot fix the bug because some clients already have created galleries relying on this behavior.
    // The goal is to support both approaches (see also app/jobs/gallery_fix.rb).
    let maxColumns = DEFAULT_MAX_COLUMNS;
    if (props.galleryColumn != null) {
      // User choice has max priority
      maxColumns = props.galleryColumn;
    } else if (props.galleryImages.length < DEFAULT_MAX_COLUMNS) {
      // If there is one or two photos, stretch the gallery to full screen width.
      maxColumns = props.galleryImages.length;
    }

    // TODO (Suggestion): make aspect ratio configurable from the admin app.
    let forcedAspectRatio = DEFAULT_ASPECT_RATIO;
    if (props.galleryColumn == null && props.galleryImages.length < DEFAULT_MAX_COLUMNS && props.galleryImages.length > 0) {
      forcedAspectRatio = DEFAULT_MAX_COLUMNS / props.galleryImages.length;
    }

    return (
      <CustomGallery
        maxColumns={maxColumns}
        photos={props.galleryImages}
        galleryPadding={props.galleryPadding}
        eventableType="CustomPage"
        eventableId={props.customPageId}
        forcedAspectRatio={forcedAspectRatio}
      />
    );
  }
  return null;
};

export default GallerySection;
