import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Slider from 'react-slick';

import { visualRegressionsMode } from '../../utils/visualRegressionsMode';
import CustomImg from '../../shared/CustomImg';
import { supportsAspectRatio } from '../../utils/dom';
import { makeStyles, classNames } from '../../utils/withStyles';

const MAX_MOBILE_PADDING = 15;

const useStyles = makeStyles(theme => ({
  container: {
    margin: ({ galleryPadding }) => `${Math.min(MAX_MOBILE_PADDING, galleryPadding)}px`,
    [theme.breakpoints.up('sm')]: {
      margin: ({ galleryPadding }) => `${galleryPadding}px`,
    },
  },
  img: {
    aspectRatio: ({ aspectRatio }) => (aspectRatio || 'auto'),
    height: ({ forcedHeight }) => (forcedHeight === undefined ? 'auto' : `${forcedHeight}px`),
    objectFit: ({ aspectRatio }) => (Number.isFinite(aspectRatio) ? 'cover' : 'auto'),
  },
}));

const getAverageAspectRatio = (galleryImages) => {
  const aspectRatios = galleryImages
    .map(image => image.imageUploadedPhoto.width / image.imageUploadedPhoto.height)
    .filter(aspectRatio => Number.isFinite(aspectRatio));

  return aspectRatios.reduce((a, b) => a + b, 0) / aspectRatios.length;
};

const SliderGallery = (props) => {
  // A fallback when aspect ratio is unsupported.
  const [forcedHeight, setForcedHeight] = useState(undefined);

  const [imgLoaded, setImgLoaded] = useState(false);
  const onImageLoaded = useCallback(() => setImgLoaded(true), []);
  const aspectRatio = useMemo(() => {
    if (props.hasDynamicHeight) {
      // 'react-slick' sometimes renders images to a thin strip.
      // It doesn't account for the image size update during the load.
      // It's hard to say why because the problem is rare and hard to reproduce.
      //
      // With specified aspect-ratio the image has always the same size.
      // aspect-ratio solves the problem, but the property should be removed for hasDynamicHeight to work.
      if (imgLoaded) return undefined;

      const firstImage = props.galleryImages[0];
      return firstImage.imageUploadedPhoto.width / firstImage.imageUploadedPhoto.height;
    }
    return getAverageAspectRatio(props.galleryImages);
  }, [props.galleryImages, props.hasDynamicHeight, imgLoaded]);

  const classes = useStyles({
    aspectRatio,
    forcedHeight,
    galleryPadding: props.galleryPadding || 0,
  });

  const updateForcedHeight = useCallback(() => {
    if (!supportsAspectRatio) {
      setForcedHeight(Number.isFinite(aspectRatio) ? window.innerWidth / aspectRatio : undefined);
    }
  }, [aspectRatio]);

  useEffect(() => {
    updateForcedHeight();
    window.addEventListener('resize', updateForcedHeight);
    return () => {
      window.removeEventListener('resize', updateForcedHeight);
    };
  }, [updateForcedHeight]);

  const settings = {
    adaptiveHeight: props.hasDynamicHeight,
    autoplay: !props.hasDynamicHeight && !visualRegressionsMode,
    autoplaySpeed: 4500,
    dots: true,
    infinite: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    slidesToShow: 1,
  };

  return (
    <div className={classes.container}>
      <Slider {...settings}>
        {props.galleryImages.map(image => (
          <CustomImg
            alt={image.description ? image.description : `${props.restaurant.name || ''} Gallery`}
            className={classNames('gallery-section-img', classes.img)}
            key={image.id}
            src={image.imageUrl}
            onLoad={onImageLoaded}
          />
        ))}
      </Slider>
    </div>
  );
};

export default SliderGallery;
